// Import neccessary packages
import { NavLink } from "react-router-dom";
import { useState } from "react";

// Import components
import MobileMenu from "../mobile-menu/layout";

// Import styles and assets
import "./style.css";
import "./responsive.css";
import "./animation.css";
import logo from "../../assets/website-logo.jpg";
import menuIcon from "../../assets/light-mode/menu-icon.svg";
import closeIcon from "../../assets/light-mode/close-menu.svg";

// React header component function
export default function Header() {

    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };

    return (
        <>
            <header className="header-section" id="header-section">
                <nav className="header-logo-title" id="header-logo-title">
                    <NavLink to="/" end className={({ isActive }) => (isActive ? "active-link" : "")}>
                        <img src={logo} alt="Website logo" className="header-logo" id="header-logo" />
                        <div className="header-title" id="header-title">
                            <b>Sudarshan</b> <span style={{
                                fontSize: 12
                            }}>Aerial Solutions Pvt Ltd.</span>
                        </div>
                    </NavLink>
                </nav>
                <nav className="header-options" id="header-options">
                    <ul>
                        <li><NavLink to="/" end className={({ isActive }) => (isActive ? "active-link" : "")}>Home</NavLink></li>
                        <li><NavLink to="/our-products" className={({ isActive }) => (isActive ? "active-link" : "")}>Products</NavLink></li>
                        <li><NavLink to="/about-us" className={({ isActive }) => (isActive ? "active-link" : "")}>About Us</NavLink></li>
                        <li><NavLink to="/blogs" className={({ isActive }) => (isActive ? "active-link" : "")}>Blogs</NavLink></li>
                        <li><NavLink to="/meet-our-team" className={({ isActive }) => (isActive ? "active-link" : "")}>Meet Our Team</NavLink></li>
                        <li><NavLink to="/contact-us" className={({ isActive }) => (isActive ? "active-link" : "")}>Contact Us</NavLink></li>
                    </ul>
                </nav>
                <div className="header-button-container" id="header-button-container">
                    <button className="get-in-touch-button">Get in touch</button>
                </div>
                <div className="options-container" id="options-container">
                    <img
                        src={isMobileMenuOpen ? closeIcon : menuIcon}
                        className="show-menu-icon"
                        onClick={toggleMobileMenu}
                        alt={isMobileMenuOpen ? "Close menu" : "Open menu"}
                    />
                </div>
            </header>
            {isMobileMenuOpen && <MobileMenu toggleMobileMenu={toggleMobileMenu} />}
        </>
    );
}
