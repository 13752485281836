import { useEffect } from "react";
import "./style.css";
import "./responsive.css";
import phoneIcon from "../../assets/light-mode/phone-call.svg";
import wpIcon from "../../assets/light-mode/whatsapp-icon.svg";
import locationIcon from "../../assets/light-mode/location-icon.svg";
import emailIcon from "../../assets/light-mode/email-icon.svg";
import instagramIcon from "../../assets/light-mode/instagram-icon.svg";
import linkedinIcon from "../../assets/light-mode/linkedin-icon.svg";

// Function to dial number
const dialNumber = () => {
    window.location.href = 'tel:9356983825'; 
}

// Function to open WhatsApp
const openWp = () => {
    window.location.href = `https://wa.me/9356983825`;
}

const ContactForm = () => {
    return(
        <form className="leave-a-message-form" id="leave-a-message-form" action="#" method="post">
        <div className="first-last-name">
            <input type="text" className="user-inputs" placeholder="First name" name="first_name" required />
            <input type="text" className="user-inputs second-userinput" placeholder="Last name" name="last_name" required />
        </div>
        <div>
            <input type="email" className="user-inputs single-userinput" placeholder="E-mail Address" name="email" required />
        </div>
        <div>
            <input type="tel" className="user-inputs single-userinput" placeholder="Mobile Number" name="mobile_number" pattern="[0-9]{10}" title="Please enter a valid 10-digit mobile number" />
        </div>
        <div>
            <textarea className="user-inputs single-userinput" placeholder="Leave a message" name="message" required></textarea>
        </div>
        <button type="submit" className="submit-button">Submit</button>
    </form>
    );
}

// About component function
export default function ContactUS() {

    useEffect(() => {
        // Set title
        document.title = "Contact Us - Sudarshan Aerial Solutions Pvt. Ltd";
        
        // Scroll to top smoothly on mount
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, []);

    // Return UI
    return (
        <section className="contact-us-section" id="contact-us-section">
            <div className="contact-us-heading-container" id="contact-us-heading-container">
                Contact Us
            </div>
            <div className="contact-us-heading-container-two">
                Reach out to us to discuss and know more about us.
            </div>
            <div className="contact-social-media">
                    <a href="https://www.linkedin.com/company/sudarshan-aerial-solutions" target="_blank">
                        <img src={linkedinIcon}></img>
                    </a>
                    <a href="https://www.instagram.com/sudarshanaerialsolutions" target="_blank">
                        <img src={instagramIcon}></img>
                    </a>
                </div>
            <div className="contact-us-content">
                <div className="location-container">
                    <img src={locationIcon} alt="Location Icon" className="icon" />
                    <address>
                        Sudarshan Aerial Solutions Private Limited<br />
                        N6, CIDCO, Near Prozone Mall Chhtrapati Sambhajinagar - 431003, Maharashtra.
                    </address>
                </div>
                <div className="location-container">
                    <img src={emailIcon} alt="Email Icon" className="icon" />
                    <a href="mailto:contact@sudarshanaerialsolutions.com" className="contact-info">
                        contact@sudarshanaerialsolutions.com</a>
                </div>
                <div className="location-container">
                    <img src={phoneIcon} alt="Phone Icon" className="icon" />
                    <a href="tel:9356983825" className="contact-info">+91 9356983825</a>
                </div>
            </div>
            <div className="contact-us-button-container">
                <button className="call-us-button" onClick={openWp}>
                    WhatsApp Us <img src={wpIcon} alt="WhatsApp Icon" height="18" />
                </button>
            </div>
        </section>
    );
}
