// Import data
import Articles from "../data/articles";

// Import assets
import ViewArticleIcon from "../../../assets/light-mode/view-article-icon.svg";
import { Link } from "react-router-dom";

// Blog post component
const BlogPost = ({ post }) => {
    return (
        <div className="blog-post-card" id="blog-post-card">
            <img
                src={post.articleThumbnailURL}
                alt="Blog post thumbnail"
                className="blog-post-card-image"
                id="blog-post-card-image"
            />
            <h3 className="blog-post-card-title" id="blog-title">
                {post.articleTitle}
                <img
                    src={ViewArticleIcon}
                    alt="View article"
                    className="blog-post-card-title_image"
                    id="blog-post-card-title_image"
                />
            </h3>
            <p className="blog-post-card_description" id="blog-post-card_description">
                {post.articleDescription}
            </p>
            <div className="read-blog-button-container" id="read-blog-button-container">
                <button className="know-more-button">
                    <Link to={`/blog/${post.articleId}`}>Read blog</Link>
                </button>
            </div>
            <div className="blog-post-card-information-container" id="blog-post-card-information-container">
                <div className="blog-post-card-information-container-main" id="blog-post-card-information-container-main">
                    <img
                        src={post.articleProfileImage}
                        alt="Article writer dp"
                        className="blog-post-card-profile-image"
                        id="blog-post-card-profile-image"
                    />
                    <div className="blog-post-card-profile-information" id="blog-post-card-profile-information">
                        <div className="blog-post-card-prfile-name" id="blog-post-card-prfile-name">
                            {post.articleProfileName}
                        </div>
                        <div className="blog-post-card-prfile-article-date" id="blog-post-card-prfile-article-date">
                            {post.articleProfileDate}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );  
}

// Blog post container
export default function TopBlogsPostContainer() {
    return (
        <div className="top-blogs-post-container" id="top-blogs-post-container">
            {Articles.map(post => (
                <Link to={`/blog/${post.articleId}`}><BlogPost key={post.articleId} post={post} /></Link>
            ))}
        </div>
    );
}
