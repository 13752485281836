import DroneOverview from "../article/data/article-01/article";

const articles = [
    {
        articleId: "1",
        articleTitle: "Introduction to Drones",
        articleDescription: "In recent years, drones have become a significant technological breakthrough, transforming various industries and everyday life.",
        articleThumbnailURL: "https://res.cloudinary.com/dkloamkhh/image/upload/v1723631896/f7c6nhfnvuyfxsczcvxz.png",
        articleProfileImage: "https://sudarshanaerialsolutions.com/static/media/sumit-aher-dp.4dccb40f960523479741.jpg",
        articleProfileName: "Sumit Ashok Aher",
        articleProfileDate: "13 Aug 2024",
        articleContent: <DroneOverview />
    },
];

export default articles;