// Import react and react dom
import React, { StrictMode } from "react";
import ReactDOM from "react-dom/client";

// Stylesheets
import "./style.css";

// Import the react app
import ReactApp from "./App";

// React root container configuration
const app = document.getElementById("app");
const root = ReactDOM.createRoot(app);

// Render the react app
root.render(
    <ReactApp></ReactApp>
);