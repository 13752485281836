// Import assets and styles
import "./style.css";
import RecImg from "../../assets/seed-funding.jpeg"

// Recognization component
export default function Recognization() {
    return(
        <section className="reconginazation-section">
            
        </section>
    )
}