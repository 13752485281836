import React from 'react';
import './style.css'; // Create a separate CSS file for styling

const DroneOverview = () => {
    return (
        <div className="drone-overview">
            <h1>Overview of Drone Technology</h1>
            <p>Drones, also known as unmanned aerial vehicles (UAVs) or unmanned aircraft systems (UAS), are aircraft that operate without a human pilot on board. They are controlled remotely by a pilot on the ground or autonomously through pre-programmed flight plans.</p>

            <h2>Core Technology of Drones</h2>
            <ul>
                <li><strong>Flight Controllers:</strong> The brain of the drone, managing flight dynamics, stability, and navigation.</li>
                <li><strong>Propulsion System:</strong> Motors and propellers that generate lift and thrust.</li>
                <li><strong>Navigation Systems:</strong> GPS and inertial measurement units (IMUs) for navigation and positioning.</li>
                <li><strong>Sensors:</strong> Cameras, lidar, and other sensors for data collection and obstacle detection.</li>
                <li><strong>Communication Systems:</strong> Radio frequencies or wireless systems for command reception and data transmission.</li>
            </ul>

            <h2>Different Types of Drones</h2>
            <div className="drone-types">
                <h3>Consumer Drones</h3>
                <ul>
                    <li><strong>Quadcopters:</strong> Popular for recreational flying, aerial photography, and video recording.</li>
                    <li><strong>Foldable Drones:</strong> Portable drones ideal for travel and personal use.</li>
                </ul>

                <h3>Commercial Drones</h3>
                <ul>
                    <li><strong>Fixed-Wing Drones:</strong> Used for long-duration flights and large-area surveys.</li>
                    <li><strong>Hexacopters and Octocopters:</strong> Enhanced stability and payload capacity for professional use.</li>
                </ul>

                <h3>Industrial Drones</h3>
                <ul>
                    <li><strong>Surveying Drones:</strong> Equipped with high-resolution cameras and mapping tools for inspections and monitoring.</li>
                    <li><strong>Agricultural Drones:</strong> Designed for crop monitoring, pesticide application, and soil analysis.</li>
                </ul>

                <h3>Military Drones</h3>
                <ul>
                    <li><strong>Reconnaissance Drones:</strong> For surveillance and intelligence gathering.</li>
                    <li><strong>Combat Drones:</strong> Armed drones for precision strikes and tactical operations.</li>
                </ul>
            </div>

            <h2>Applications of Drones in Various Industries</h2>
            <div className="drone-applications">
                <h3>Agriculture</h3>
                <ul>
                    <li><strong>Crop Monitoring:</strong> Analyze crop health and optimize irrigation.</li>
                    <li><strong>Precision Farming:</strong> Accurate application of fertilizers and pesticides.</li>
                </ul>

                <h3>Real Estate</h3>
                <ul>
                    <li><strong>Aerial Photography:</strong> High-quality images and videos of properties.</li>
                    <li><strong>Virtual Tours:</strong> Immersive virtual property tours.</li>
                </ul>

                <h3>Construction</h3>
                <ul>
                    <li><strong>Site Inspections:</strong> Real-time monitoring of construction sites.</li>
                    <li><strong>Mapping and Surveying:</strong> 3D maps and models for planning and design.</li>
                </ul>

                <h3>Emergency Services</h3>
                <ul>
                    <li><strong>Search and Rescue:</strong> Assist in locating missing persons.</li>
                    <li><strong>Disaster Response:</strong> Real-time imagery of disaster areas.</li>
                </ul>

                <h3>Environmental Monitoring</h3>
                <ul>
                    <li><strong>Wildlife Conservation:</strong> Monitor wildlife and assess habitats.</li>
                    <li><strong>Climate Research:</strong> Collect data on environmental changes.</li>
                </ul>

                <h3>Delivery Services</h3>
                <ul>
                    <li><strong>Medical Deliveries:</strong> Transport medical supplies to remote locations.</li>
                    <li><strong>Retail Deliveries:</strong> Explore drone delivery for faster package transport.</li>
                </ul>
            </div>

            <h2>Conclusion</h2>
            <p>Drones represent a remarkable convergence of technology and innovation, offering a wide range of applications across various industries. From enhancing agricultural practices to revolutionizing real estate marketing, drones are proving to be invaluable tools with the potential to transform how we approach numerous tasks. As technology continues to advance, the possibilities for drone applications are boundless, promising even greater advancements in the future.</p>
            <p>Whether you're a hobbyist interested in aerial photography or a professional looking to leverage drone technology for business purposes, understanding the fundamentals of drones and their diverse applications is essential. Stay tuned to our blog for more insights and updates on the evolving world of drones!</p>
            <p>Feel free to reach out to <strong>Sudarshan Aerial Solutions</strong> to know more about our drone services and solutions!</p>
        </div>
    );
}

export default DroneOverview;
