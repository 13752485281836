// Import styles
import "./style.css";
import "./responsive.css";

// Import components
import BlogSectionHighlight from "./components/BlogSectionHighlight"
import BlogPostsContainer from "./components/BlogPostsContainer";

// Blog component
export default function BlogSection() {
    return (
        <section className="blog-section" id="blog-section">
            <BlogSectionHighlight />
            <BlogPostsContainer />
        </section>
    )
}