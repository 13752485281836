// Blog section highlight
export default function BlogSectionHighlight() {
    return (
        <div className="blog-section-highlight-container" id="blog-section-highlight-container">
            <h1 className="blog-section-highlight-container_h1" id="blog-section-highlight-container_h1">
                Our Blogs
            </h1>
            <h2 className="blog-section-highlight-container_h2" id="blog-section-highlight-container_h2">
                Explore our daily blog to learn how we can change the future of farming, surveillance, and other sections using drone technology.
            </h2>
        </div>
    );
}
