import React from "react";
import DP1 from "../../assets/sumit-aher-dp.jpg";
import DP2 from "../../assets/avishkar-patil-dp.jpg";
import DP3 from "../../assets/prathmesh-rajput-dp.jpg";
import DP4 from "../../assets/sunil-bokare-dp.png";
import LinkedInIcon from "../../assets/light-mode/linkedin-icon.svg";
import InstagramIcon from "../../assets/light-mode/instagram-icon.svg";

// Member profile 1: Sumit Ashok Aher
const Member1 = () => (
    <div className="team-member-profile" id="team-member-profile">
        
        <div className="member-details-container" id="member-details-container">
            <div className="member-name" id="member-name">Sumit Ashok Aher</div>
            <div className="member-role" id="member-role">Founder & CEO</div>
        </div>
        <div className="member-social-media" id="member-social-media">
            <a href="https://www.linkedin.com/in/sumit-aher-73447a1b1" target="_blank" rel="noreferrer">
                <img src={LinkedInIcon} alt="LinkedIn Icon" className="linkedin-icon" id="linkedin-icon" />
            </a>
            <a href="https://www.instagram.com/sumitaherpatil9300" target="_blank" rel="noreferrer">
                <img src={InstagramIcon} alt="Instagram Icon" className="instagram-icon" id="instagram-icon" />
            </a>
        </div>
    </div>
);

// Member profile 2: Avishkar Patil
const Member2 = () => (
    <div className="team-member-profile" id="team-member-profile">
        
        <div className="member-details-container" id="member-details-container">
            <div className="member-name" id="member-name">Avishkar Patil</div>
            <div className="member-role" id="member-role">CO-Founder & CMO</div>
        </div>
        <div className="member-social-media" id="member-social-media">
            <a href="https://www.linkedin.com/in/avishkar-chondhe-59b8b91b9" target="_blank" rel="noreferrer">
                <img src={LinkedInIcon} alt="LinkedIn Icon" className="linkedin-icon" id="linkedin-icon" />
            </a>
            <a href="https://www.instagram.com/patil_avi_2003" target="_blank" rel="noreferrer">
                <img src={InstagramIcon} alt="Instagram Icon" className="instagram-icon" id="instagram-icon" />
            </a>
        </div>
    </div>
);

// Member profile 3: Sunil Bokare
const Member3 = () => (
    <div className="team-member-profile" id="team-member-profile">
       
        <div className="member-details-container" id="member-details-container">
            <div className="member-name" id="member-name">Sunil Bokare</div>
            <div className="member-role" id="member-role">Developer & Technical</div>
        </div>
        <div className="member-social-media" id="member-social-media">
            <a href="https://www.linkedin.com/in/sunil-bokare-7a240b266" target="_blank" rel="noreferrer">
                <img src={LinkedInIcon} alt="LinkedIn Icon" className="linkedin-icon" id="linkedin-icon" />
            </a>
            <a href="https://www.instagram.com/sunil_bokare_patil_192" target="_blank" rel="noreferrer">
                <img src={InstagramIcon} alt="Instagram Icon" className="instagram-icon" id="instagram-icon" />
            </a>
        </div>
    </div>
);




// Export all member profiles
export default function MemberProfiles() {
    return (
        <>
            <Member1 />
            <Member2 />
            <Member3 />
        </>
    );
}
