import { NavLink } from "react-router-dom";
import { useEffect, useState } from "react";

// Import assets and styles
import "./style.css";
import "./responsive.css";
import Drone1 from "../../assets/drone-image-one.jpeg";
import Drone2 from "../../assets/drone-image-two.jpeg";

export default function AboutUS() {
    // State to manage paragraph visibility
    const [isExpanded, setIsExpanded] = useState({
        firstParagraph: false,
        secondParagraph: false,
    });

    useEffect(() => {
        // Set title and scroll to top on mount
        document.title = "About Us - Sudarshan Aerial Solutions Pvt. Ltd.";
        window.scrollTo({ top: 0, behavior: "smooth" });
    }, []);

    // Toggle visibility function
    const toggleParagraph = (paragraph) => {
        setIsExpanded((prevState) => ({
            ...prevState,
            [paragraph]: !prevState[paragraph],
        }));
    };

    // Return UI
    return (
        <section className="about-section" id="about-section">
            <div className="about-heading-container">About Us</div>
            <div className="about-heading-container-two">
                We are Sudarshan Aerial Solutions Pvt. Ltd.
            </div>
            <div className="contact-us-button-container">
                <NavLink
                    to="/contact-us"
                    className={({ isActive }) => (isActive ? "active-link" : "")}
                >
                    <button className="contact-us-button">Contact Us</button>
                </NavLink>
            </div>
            <div className="about-us-content">
                <img src={Drone1} className="about-us-image" alt="Drone 1" />
                <p>
                    At Sudarshan Aerial Solutions Pvt. Ltd., we are passionate about revolutionizing the world of drones and agricultural technology.
                    {isExpanded.firstParagraph && (
                        <span className="extra-paragraph">
                            Founded on innovation and a deep commitment to solving real-world challenges, we specialize in providing high-quality drone components, 3D-printed parts, and comprehensive crop management solutions tailored to meet diverse needs. Our offerings include precision-engineered propellers, motors, ESCs, remotes, and customized drone covers designed to enhance performance and durability. Beyond parts, we deliver cutting-edge agriculture drones that empower farmers to monitor crops, optimize yields, and embrace sustainable farming practices.
                        </span>
                    )}
                    <span
                        className="read-more-span"
                        onClick={() => toggleParagraph("firstParagraph")}
                    >
                        {isExpanded.firstParagraph ? "Read less" : "Read more"}
                    </span>
                </p>
            </div>
            <div className="about-heading-container two-m">Our Vision</div>
            <div className="about-us-content">
                <img src={Drone2} className="about-us-image" alt="Drone 2" />
                <p>
                    Our vision is to redefine the future of industries through pioneering drone technology solutions.
                    {isExpanded.secondParagraph && (
                        <span className="extra-paragraph">
                            We aim to bridge the gap between technology and practicality, empowering businesses, professionals, and farmers alike. At Sudarshan Aerial Solutions, innovation takes flight as we collaborate to explore new heights, delivering reliable products and personalized services with a shared vision for a better, sustainable future.
                        </span>
                    )}
                    <span
                        className="read-more-span"
                        onClick={() => toggleParagraph("secondParagraph")}
                    >
                        {isExpanded.secondParagraph ? "Read less" : "Read more"}
                    </span>
                </p>
            </div>
        </section>
    );
}
