// MobileMenu.js
import React from "react";
import { NavLink } from "react-router-dom";
import "./style.css";

const MobileMenu = ({ toggleMobileMenu }) => {
    return (
        <div className="mobile-menu-container">
            <ul className="menu-option-list">
                <li>
                    <NavLink to="/" end className={({ isActive }) => (isActive ? "active-link" : "")} onClick={toggleMobileMenu}>
                        Home
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/our-products" className={({ isActive }) => (isActive ? "active-link" : "")} onClick={toggleMobileMenu}>
                        Products
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/about-us" className={({ isActive }) => (isActive ? "active-link" : "")} onClick={toggleMobileMenu}>
                        About Us
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/blogs" className={({ isActive }) => (isActive ? "active-link" : "")} onClick={toggleMobileMenu}>
                        Blogs
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/meet-our-team" className={({ isActive }) => (isActive ? "active-link" : "")} onClick={toggleMobileMenu}>
                        Meet Our Team
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/contact-us" className={({ isActive }) => (isActive ? "active-link" : "")} onClick={toggleMobileMenu}>
                        Contact Us
                    </NavLink>
                </li>
            </ul>
        </div>
    );
};

export default MobileMenu;
