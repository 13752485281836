import DP5 from "../../assets/shrikrishna-pawar-dp.jpg";
import DP6 from "../../assets/arvind-chel-dp.jpg";
import DP7 from "../../assets/s-n-harke-dp.jpg";
import DP8 from "../../assets/annasaheb-dp.jpg";
import DP9 from "../../assets/rupali-dp.jpg";
import LinkedInIcon from "../../assets/light-mode/linkedin-icon.svg";

//  Mentor profile 1: Shrikrishna B. Pawar
const Mentor1 = () => (
    <div className="team-member-profile" id="team-member-profile">
        <div className="member-profile-image-container" id="member-profile-image-container">
            <img src={DP5} alt="Profile" className="member-profile-image" id="member-profile-image" />
        </div>
        <div className="member-details-container" id="member-details-container">
            <div className="member-name" id="member-name">Shrikrishna B. Pawar</div>
            <div className="member-role" id="member-role">Assistant & Professor - MGMU JNEC.</div>
        </div>
        <div className="member-social-media" id="member-social-media">
            <a href="https://www.linkedin.com/in/shrikrishna-pawar-4ab58512a" target="_blank" rel="noreferrer">
                <img src={LinkedInIcon} alt="LinkedIn Icon" className="linkedin-icon" id="linkedin-icon" />
            </a>
        </div>
    </div>
);

// Mentor profile 2: Dr. Arvind Chel
const Mentor2  = () => (
    <div className="team-member-profile" id="team-member-profile">
        <div className="member-profile-image-container" id="member-profile-image-container">
            <img src={DP6} alt="Profile" className="member-profile-image" id="member-profile-image" />
        </div>
        <div className="member-details-container" id="member-details-container">
            <div className="member-name" id="member-name">Dr. Arvind Chel</div>
            <div className="member-role" id="member-role">HOD , Mechanical Department 
            MGMU JNEC.</div>
        </div>
        <div className="member-social-media" id="member-social-media">
            <a href="https://www.linkedin.com/in/dr-arvind-chel-51186411" target="_blank" rel="noreferrer">
                <img src={LinkedInIcon} alt="LinkedIn Icon" className="linkedin-icon" id="linkedin-icon" />
            </a>
        </div>
    </div>
);


// Mentor profile 3: Dr. Arvind Chel
const Mentor3  = () => (
    <div className="team-member-profile" id="team-member-profile">
        <div className="member-profile-image-container" id="member-profile-image-container">
            <img src={DP7} alt="Profile" className="member-profile-image" id="member-profile-image" />
        </div>
        <div className="member-details-container" id="member-details-container">
            <div className="member-name" id="member-name">Dr. S. N. Harke</div>
            <div className="member-role" id="member-role">Professor, Director and Research Supervisor,
            MGM IBT.</div>
        </div>
        <div className="member-social-media" id="member-social-media">
            <a href="https://www.linkedin.com/in/dr-arvind-chel-51186411" target="_blank" rel="noreferrer">
                <img src={LinkedInIcon} alt="LinkedIn Icon" className="linkedin-icon" id="linkedin-icon" />
            </a>
        </div>
    </div>
);

// Mentor profile 4: Dr. Annasaheb S. Khemnar
const Mentor4  = () => (
    <div className="team-member-profile" id="team-member-profile">
        <div className="member-profile-image-container" id="member-profile-image-container">
            <img src={DP8} alt="Profile" className="member-profile-image" id="member-profile-image" />
        </div>
        <div className="member-details-container" id="member-details-container">
            <div className="member-name" id="member-name">Dr. Annasaheb S. Khemnar</div>
            <div className="member-role" id="member-role">Professor, Director and Research Supervisor, MGM IBT.</div>
        </div>
        <div className="member-social-media" id="member-social-media">
            <a href="https://www.linkedin.com/in/dr-arvind-chel-51186411" target="_blank" rel="noreferrer">
                <img src={LinkedInIcon} alt="LinkedIn Icon" className="linkedin-icon" id="linkedin-icon" />
            </a>
        </div>
    </div>
);


// Mentor profile 5: Dr. Rupali Taur
const Mentor5  = () => (
    <div className="team-member-profile" id="team-member-profile">
        <div className="member-profile-image-container" id="member-profile-image-container">
            <img src={DP9} alt="Profile" className="member-profile-image" id="member-profile-image" />
        </div>
        <div className="member-details-container" id="member-details-container">
            <div className="member-name" id="member-name">Dr. Rupali Taur</div>
            <div className="member-role" id="member-role">Assistant Professor in Institute of Biosciences and Technology, MGM IBT.</div>
        </div>
        <div className="member-social-media" id="member-social-media">
            <a href="https://www.linkedin.com/in/dr-arvind-chel-51186411" target="_blank" rel="noreferrer">
                <img src={LinkedInIcon} alt="LinkedIn Icon" className="linkedin-icon" id="linkedin-icon" />
            </a>
        </div>
    </div>
);


// Export all mentor profiles
export default function MemberProfiles() {
    return (
        <>
            <Mentor1 />
            <Mentor2 />
            <Mentor3 />
            <Mentor4 />
            <Mentor5 />
        </>
    );
}