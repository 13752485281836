import React, { useEffect } from "react";
import "./style.css"; // Add the updated CSS here
import "./res.css";

// Import layouts for team members and mentors
import MemberProfiles from "./MemberProfiles";
import Mentors from "./Mentors";

export default function TeamOverview() {
    useEffect(() => {
        // Set the document title
        document.title = "Meet Our Team - Sudarshan Aerial Solutions Pvt. Ltd";
        
        // Smoothly scroll to the top of the page
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }, []);

    return (
        <section className="team-overview-section" id="team-overview-section">
            <div className="team-overview-heading" id="team-overview-heading">
                <div>Meet Our Team</div>
                <div className="two">
                    This is our incredible team who's working behind the scenes and making everything happen.
                </div>
            </div>

            <div className="team-members-container" id="team-members-container">
                <MemberProfiles />
            </div>

            <div className="team-overview-heading" id="mentors-overview-heading">
                <div>Meet Our Mentors</div>
                <div className="two">
                    These are our mentors and motivators, who encourage us to make this happen.
                </div>
            </div>

            <div className="team-members-container" id="mentors-container">
                <Mentors />
            </div>
        </section>
    );
}
