// Import necessary packages
import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// React components
import Header from "./components/header/layout";
import Footer from "./components/footer/layout";

// React layouts
import TeamOverview from "./layouts/team-overview/layout";
import Products from "./layouts/products/layout";
import LandingPage from "./layouts/landing-page/layout";
import AboutPage from "./layouts/about-page/layout";
import ContactUS from "./layouts/contact-us/layout";
import Blog from "./layouts/blog-section/layout";
import ArticlePage from "./layouts/blog-section/article/layout";

// Update main-content margin on window resize
const updateMainMargin = () => {
    // Update the main content margin
    const header = document.getElementById("header-section");
    if (header) {
        const headerHeight = header.offsetHeight;
        const mainContent = document.getElementById("main-content");
        if (mainContent) {
            mainContent.style.marginTop = headerHeight + "px";
        }
    }
}

// React app function
export default function ReactApp() {

    // Handle window resize
    useEffect(() => {

        // Update margin initially
        updateMainMargin();

        // Add window resize event
        window.addEventListener("resize", updateMainMargin);

        // Clean up window resize event
        return () => {
            window.removeEventListener("resize", updateMainMargin);
        };
    }, []);

    // Return the UI
    return(
        <Router>
            <Header />
            <main className="main-content" id="main-content">
                <Routes>
                    <Route path="/" element={<LandingPage />} />
                    <Route path="/home" element={<LandingPage />} />
                    <Route path="/our-products" element={<Products />} />
                    <Route path="/about-us" element={<AboutPage />} />
                    <Route path="/blogs" element={<Blog />} />
                    <Route path="/blog/:articleId" element={<ArticlePage />} />
                    <Route path="/meet-our-team" element={<TeamOverview />} />
                    <Route path="/contact-us" element={<ContactUS />} />
                </Routes>
            </main>
            <Footer />
        </Router>
    );
}
