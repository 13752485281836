import React, { useState } from 'react';
import './style.css'; // Ensure this points to your CSS file

// Importing product images
import Product1 from "../../assets/product-1.png";
import Product2 from "../../assets/product-2.png";
import Product3 from "../../assets/product-3.png";
import Product4 from "../../assets/product-4.png";

const ProductPage = () => {
    const [selectedProduct, setSelectedProduct] = useState(null);

    // Product data
    const productData = [
        {
            id: 1,
            name: '30A BLDC ESC',
            image: Product1,
            price: '450 RS',
            description: 'Electronic Speed Controller for Brushless Motors',
            specifications: ['Item Type: Accessory', 'Material: ABS Plastic', 'Weight: 230g'],
            descriptionText: 'The 30A BLDC ESC is an electronic speed controller designed for brushless DC motors.',
        },
        {
            id: 2,
            name: 'Landing Gear Landing Skid',
            image: Product2,
            price: '446 RS',
            description: 'Four multi-axis tripod cushion D8 (black and yellow)',
            specifications: ['Material: ABS Plastic', 'Weight: 230g', 'Height: 200mm'],
            descriptionText: 'The landing gear adds extra protection to your flying platform and equipment.',
        },
        {
            id: 3,
            name: 'Robocraze Propeller Set for Quadcopter',
            image: Product3,
            price: '94 RS',
            description: 'Propeller Set for RC Vehicles',
            specifications: ['Material: ABS', 'Rotation: CW, CCW', 'Weight: 16g'],
            descriptionText: 'High-quality propellers specially designed for multi-copters.',
        },
        {
            id: 4,
            name: 'F330 Glass Fiber Mini Quadcopter Frame',
            image: Product4,
            price: '1050 RS',
            description: 'Arm Size (L x W) mm:  155 x 34',
            specifications: ['Motor Mount Hole Dia. (mm):	3', 'Weight (g):	160', 'Shipping Weight:	0.18 kg', 'Shipping Dimensions:	18 × 9 × 3 cm',],
    
            descriptionText: 'Introducing our Made in India Drone Frame, meticulously crafted to ensure assured quality and exceptional compatibility surpassing other options in the market.',
        },
    ];

    // Handle product card click
    const handleProductClick = (product) => {
        setSelectedProduct(product);
    };

    // Close modal
    const closeModal = () => {
        setSelectedProduct(null);
    };

    // WhatsApp Purchase URL (you can replace the number with your actual business number)
    const getWhatsAppLink = (productName, productPrice) => {
        const message = `Hi, I am interested in buying the product "${productName}" for ${productPrice}.`;
        const phoneNumber = '9356983825'; // Replace with your WhatsApp business number
        return `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
    };

    return (
        <div className="products-section">
            {/* Product Grid */}
            <div className="product-grid">
                {productData.map((product) => (
                    <div
                        key={product.id}
                        className="product-card"
                        onClick={() => handleProductClick(product)}
                    >
                        <img src={product.image} alt={product.name} className="product-image" />
                        <h3>{product.name}</h3>
                        <p>Price: {product.price}</p>
                    </div>
                ))}
            </div>

            {/* Product Modal */}
            {selectedProduct && (
                <div className="modal">
                    <div className="modal-content modal-fullsize">
                        <span className="close-button" onClick={closeModal}>
                            &times;
                        </span>
                        <div className="modal-body">
                            <img src={selectedProduct.image} alt={selectedProduct.name} className="modal-image" />
                            <h1>{selectedProduct.name}</h1>
                            <p><strong>Price:</strong> {selectedProduct.price}</p>
                            <p><strong>Description:</strong> {selectedProduct.descriptionText}</p>
                            <h3>Specifications:</h3>
                            <ul>
                                {selectedProduct.specifications.map((spec, index) => (
                                    <li key={index}>{spec}</li>
                                ))}
                            </ul>

                            {/* WhatsApp Button */}
                            <a
                                href={getWhatsAppLink(selectedProduct.name, selectedProduct.price)}
                                className="whatsapp-button"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Buy via WhatsApp
                            </a>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ProductPage;
